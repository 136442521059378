/* ------------------- Main Content Padding ------------------- */
main {
  padding-top: 60px; /* Matches AppBar height */
}

/* ------------------- Global Reset ------------------- */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* ------------------- Body Styles ------------------- */

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  line-height: 1.6;
  background: transparent; /* Make body background transparent */
  color: white;
}

#root {
  background-color: #007F52;
  background: linear-gradient(-45deg, #007F52, #121212, #007F52, #121212);
  background-size: 400% 400%;
  animation: gradientBG 6s ease infinite;
}

button,
input {
  font-family: inherit;
}

/* ------------------- Transparent AppBar ------------------- */
.TransparentAppBar {
  position: fixed; /* Keeps it fixed at the top */
  top: 0;          /* Aligns it to the top of the page */
  left: 0;
  width: 100%;     /* Ensures full width */
  background-color: rgba(0, 0, 0, 0.2) !important; /* Translucent background */
  backdrop-filter: blur(15px) !important; /* Glassmorphism effect */
  -webkit-backdrop-filter: blur(15px) !important; /* Safari compatibility */
  box-shadow: 0 4px 10px rgba(0, 127, 82, 0.6) !important; /* Subtle shadow */
  z-index: 1100 !important; /* Keeps it above other elements */
  transition: all 0.3s ease-in-out; /* Smooth transitions */
}

/* Toolbar styles for padding and alignment */
.MuiToolbar-root {
  padding: 0 24px !important;         /* Horizontal padding */
  min-height: 60px !important;       /* Set consistent height */
  height: 60px !important;
  display: flex !important;          /* Flexbox alignment */
  align-items: center !important;
  justify-content: space-between !important;
}

/* Ensure links, SVGs, and images inside AppBar are transparent */
.TransparentAppBar a,
.TransparentAppBar svg,
.TransparentAppBar img {
  background: transparent !important;
  box-shadow: none !important;
  color: white !important; /* Maintain text and icon color */
}

/* Ensure header element does not add margins or shadows */
header {
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;
}

/* Fix carousel caption z-index to avoid overlap */
.carousel-caption {
  z-index: 5 !important; /* Ensure it's below the AppBar */
}

/* Gradient background for root */
html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  background: linear-gradient(-45deg, #007F52, #121212, #007F52, #121212);
  background-size: 400% 400%;
  animation: gradientBG 6s ease infinite;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


/* ------------------- Animated Gradient .MyGradient -------------------
   Goes full-width. No forced min-height, so it grows as needed.
----------------------------------------------------------------------- */
.MyGradient {
  width: 100%;
  background: linear-gradient(-45deg, #007F52, #121212, #007F52, #121212);
  background-size: 400% 400%;
  animation: gradientBG 6s ease infinite;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* ------------------- Hero Container (if you have a Hero.jsx) ------------------- */
.hero-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* no forced height, it just fits content */
}

/* Example hero text styling */
.hero-text {
  text-align: center;
  margin-bottom: 20px;
  color: white;
}

/* Example carousel images: 400px tall, or adjust as needed */
.carousel-inner img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 15px;
  box-shadow: 0px 0px 20px 3px rgba(0, 127, 82, 0.7);
}

/* Optional carousel caption positioning */
.carousel-caption {
  bottom: 20px;
}

/* Button hover effect */
button:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 15px rgba(0, 127, 82, 0.8);
  transition: all 0.3s ease-in-out;
}

/* Input focus glow */
input:focus,
textarea:focus {
  outline: none;
  border: 2px solid #007F52;
  box-shadow: 0 0 10px rgba(0, 127, 82, 0.8);
  transition: all 0.3s ease-in-out;
}

/* ------------------- Streamlined Contact Section ------------------- */
.Contact {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem 2rem;
  background: rgba(0, 0, 0, 0.7); /* Transparency preserved */
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px); /* Safari support */
  box-shadow: 0 -4px 10px rgba(0, 127, 82, 0.6);
  font-family: 'Roboto', sans-serif;
  color: #F0F0F0;
  opacity: 0.95; /* Fallback transparency */
}

/* Contact Item */
.contact-item {
  text-align: center;
  margin: 1rem;
}

/* Contact Title */
.contact-title {
  font-weight: 500;
  color: #A8E6CF;
  margin-bottom: 0.5rem;
}

/* Contact Link */
.contact-link {
  color: #F0F0F0;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease-in-out;
}

.contact-link:hover {
  color: #00FFA3; /* Highlight effect on hover */
}

/* ------------------- Height Inheritance ------------------- */
html,
body,
#root,
.MyGradient {
  height: 100%; /* Ensure full height inheritance */
}

/* ------------------- Responsive Contact Container ------------------- */
.contact-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap; /* Prevents wrapping */
  align-items: stretch; /* Ensure equal height */
  justify-content: center; /* Center horizontally */
  gap: 20px; /* Spacing between form and map */
  padding: 20px 20px; /* Padding for spacing */
  background: rgba(12, 12, 12, 0.7); /* Transparency preserved */
  backdrop-filter: blur(10px); /* Glassmorphism effect */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  border-radius: 0px;
  box-shadow: 0 8px 20px rgba(0, 127, 82, 0.6); /* Subtle shadow */
  overflow: hidden; /* Prevents any overflow */
}

/* ------------------- Mobile Adjustments ------------------- */
@media (max-width: 1024px) {
  .contact-container {
    flex-direction: column; /* Stack vertically */
    align-items: center;    /* Center items in column layout */
    padding: 30px 15px;     /* Adjust padding */
  }

  .contact-form,
  .contact-map {
    width: 100%;        /* Full width for smaller screens */
    height: auto;       /* Let height adjust naturally */
    min-width: unset;   /* Remove the fixed 300px constraint */
  }

  .contact-map {
    min-height: 300px;  /* Ensure map doesn't collapse */
  }

  .contact-map > .leaflet-container {
    width: 100%;        /* Full width for responsiveness */
    height: 300px;      /* Fixed height to avoid disappearing */
  }
}

@media (max-width: 600px) {
  .contact-container {
    padding: 20px 10px; /* Compact padding */
    gap: 15px;          /* Reduce spacing between form and map */
  }

  .contact-map > .leaflet-container {
    height: 250px;      /* Smaller height for tight spaces */
  }
}

/* ------------------- Contact Form ------------------- */
.contact-form {
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: rgba(12, 12, 12, 0.8); /* Semi-transparent black */
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 0 20px 3px rgba(0, 127, 82, 0.7); /* Subtle glow */
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 10;
}

.contact-form:hover {
  transform: scale(1.02);
  box-shadow: 0 0 25px 4px rgba(0, 127, 82, 1); /* Stronger glow */
}

/* ---------- Input Fields ---------- */
.MuiOutlinedInput-root {
  border-radius: 8px; /* Rounded corners */
  border-color: rgba(0, 255, 163, 0.6); /* Subtle green */
  transition: box-shadow 0.3s ease-in-out;
}

/* Default border - visible even when not focused */
.MuiOutlinedInput-notchedOutline {
  border: 1px solid rgba(0, 255, 163, 0.6); /* Subtle green border */
}

/* Remove glow until hover/focus */
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  box-shadow: 0 0 10px rgba(0, 255, 163, 0.8); /* Glow only on interaction */
  border: 1px solid rgba(0, 255, 163, 0.8); /* Highlighted border */
}

/* Input Text Style */
.MuiInputBase-input {
  color: #66FFB2 !important; /* Mint green text */
  padding: 12px !important; /* Padding inside field */
}

/* ---------- Floating Labels ---------- */
.MuiInputLabel-root {
  font-size: 1rem;
  color: #00FFA3 !important; /* Neon green */
  transform: translate(14px, 16px) scale(1);
  transition: all 0.2s ease-in-out;
}

/* Label Animation for Focus or Autofill */
.MuiInputLabel-root.Mui-focused,
.MuiInputLabel-root.MuiInputLabel-shrink {
  transform: translate(14px, -14px) scale(0.85); /* Float animation */
  background: rgba(12, 12, 12, 0.9); /* Background blends with input */
  padding: 0 5px;
  border-radius: 5px;
}

/* Autofill Fix */
input:-webkit-autofill,
textarea:-webkit-autofill {
  box-shadow: 0 0 10px rgba(0, 255, 163, 0.8) !important; /* Subtle glow */
  -webkit-text-fill-color: #00FFA3 !important; /* Text stays green */
  caret-color: #00FFA3 !important;
  transition: background-color 5000s ease-in-out 0s;
  background-color: transparent !important; /* Transparent autofill background */
}

/* ---------- Multiline Consistency ---------- */
.MuiOutlinedInput-multiline {
  padding: 12px !important; /* Matches single-line padding */
}

/* Ensure Multiline Labels Animate Properly */
.MuiOutlinedInput-multiline .MuiInputLabel-root {
  transform: translate(14px, 16px) scale(1); /* Default position */
}

.MuiOutlinedInput-multiline .MuiInputLabel-root.Mui-focused,
.MuiOutlinedInput-multiline .MuiInputLabel-root.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.85); /* Floating animation */
  background: rgba(12, 12, 12, 0.9); /* Dark background for floating label */
  padding: 0 5px;
  border-radius: 5px;
}

/* ---------- Send Button ---------- */
.send-button {
  width: 100%;
  padding: 15px;
  font-size: 18px;
  font-weight: 600;
  color: white;
  background-color: #007F52;
  border: none;
  border-radius: 15px;
  box-shadow: none;
  transition: all 0.3s ease-in-out;
  margin-top: auto;
}

.send-button:hover {
  box-shadow: 0 0 15px 3px rgba(0, 127, 82, 0.8);
  transform: scale(1.02);
}

/* ---------- Input Field Styles ---------- */
/* Default border for inputs (always visible) */
.MuiOutlinedInput-notchedOutline {
  border-width: 1px !important; /* Always visible border */
  border-color: rgba(0, 255, 163, 0.6) !important; /* Light green */
  transition: all 0.3s ease-in-out; /* Smooth glow transition */
}

/* Glow effect only on hover or focus */
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #00FFA3 !important; /* Neon green */
  box-shadow: 0 0 10px rgba(0, 255, 163, 0.8) !important; /* Glow effect */
}

/* REMOVE RECTANGULAR FOCUS BOX */
.MuiOutlinedInput-root.Mui-focused {
  outline: none !important; /* No focus rectangle */
}

/* Padding and alignment for input text */
.MuiOutlinedInput-input {
  padding: 12px !important; /* Consistent padding */
  color: #00FFA3 !important; /* Neon green text */
}

/* ---------- Label Styling ---------- */
/* Label color and position (no capsules) */
.MuiInputLabel-root {
  color: #00FFA3 !important; /* Neon green */
  padding: 0 4px; /* Slight padding for alignment */
  font-weight: 500; /* Make label bolder for contrast */
}

/* Floating label position (raised higher) */
.MuiInputLabel-shrink {
  transform: translate(14px, -12px) scale(1); /* Raise label slightly */
  background: transparent !important; /* No capsule */
  padding: 0 4px; /* Matches field padding */
  color: #00FFC1 !important; /* Slightly lighter green for contrast */
}

/* Autofill Overrides */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  box-shadow: 0 0 10px 2px rgba(0, 255, 163, 0.8) !important; /* Matches glow */
  -webkit-text-fill-color: #00FFA3 !important; /* Green autofill text */
  caret-color: #00FFA3 !important; /* Cursor matches text */
  background-color: transparent !important; /* Prevents white background */
}

/* ---------- Multiline (Message Box) Padding Fix ---------- */
.MuiOutlinedInput-multiline {
  padding: 12px !important; /* Matches single-line padding */
}

/* Floating Label for Multiline (Message Box) */
.MuiOutlinedInput-multiline .MuiInputLabel-shrink {
  transform: translate(14px, -12px) scale(1); /* Raised for consistency */
  color: #00FFC1 !important; /* Slightly lighter green for contrast */
}

/* ------------------- Send Button ------------------- */
.send-button {
  width: 100%;
  padding: 15px;
  font-size: 18px;
  font-weight: 600;
  color: white;
  background-color: #007F52;
  border: none;
  border-radius: 15px;
  box-shadow: none;
  transition: all 0.3s ease-in-out;
  margin-top: auto;
}

.send-button:hover {
  box-shadow: 0 0 15px 3px rgba(0, 127, 82, 0.8);
  transform: scale(1.02);
}

/* ------------------- Contact Map Container ------------------- */
.contact-map {
  flex: 1; /* Equal flex size with the form */
  min-width: 300px;
  border-radius: 15px;
  box-shadow: 0 0 20px 3px rgba(0, 127, 82, 0.7);
  transition: all 0.3s ease-in-out;
  position: relative; /* Contains the Leaflet map inside */
  overflow: hidden; /* Prevents map overflow */
  display: flex; /* Ensures dynamic height stretch */
  align-items: stretch;
}

/* Hover effect for map */
.contact-map:hover {
  transform: scale(1.02);
  box-shadow: 0 0 25px 4px rgba(0, 127, 82, 1);
}

/* Leaflet Map inside Contact Map */
.contact-map > .leaflet-container {
  width: 100%; /* Fills parent width */
  height: 100%; /* Fills parent height */
  border-radius: 15px; /* Matches parent radius */
  z-index: 1; /* Places it behind other content */
}

.leaflet-popup-content {
  font-family: 'Roboto', sans-serif; /* Use Roboto font */
  font-size: 16px;                   /* Adjust size for readability */
  font-weight: 500;                  /* Slightly bold for emphasis */
  color: #00CC66;                    /* Techy green accent */
  text-align: center;                /* Centered text */
}

.leaflet-popup-tip-container {
  display: none; /* Optionally hide the little pointer arrow */
}

/*---------------------Pixel Divider---------------------*/
.pixel-divider {
  width: 300px;                     /* Adjust width as needed */
  height: 60px;                     /* Match AppBar height */
  mask-image: url('/images/pixelmask.png');          /* Modern browsers */
  -webkit-mask-image: url('/images/pixelmask.png'); /* Safari fallback */
  mask-repeat: no-repeat;
  mask-size: cover;
  background: linear-gradient(90deg, #007F52, #0c0c0c); /* Gradient fill inside the mask */
}

/* For Safari (-webkit- prefixed) */
@supports (-webkit-mask-image: url("")) {
  .pixel-divider {
    -webkit-mask-image: url('/images/pixelmask.png');
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: cover;
  }
}

/* ------------------- Footer Section ------------------- */
.footer {
  padding: 20px;
  background: rgba(12, 12, 12, 0.85);
  backdrop-filter: blur(10px);
  box-shadow: 0 -4px 10px rgba(0, 127, 82, 0.6);
  text-align: center;
  font-family: 'Roboto', sans-serif;
  color: #F0F0F0;
}

/* Footer Navigation */
.footer-nav {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.nav-list {
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 0;
  margin: 0;
}

/* Footer Links */
.footer .nav-link {
  color: #A8E6CF;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.footer .nav-link:hover {
  color: #00FFA3; /* Highlight on hover */
}

/* Footer Text */
.footer-text {
  margin: 0;
  font-size: 0.9rem;
  color: #F0F0F0;
  opacity: 0.8;
}

/* ---------------- Services Styles ---------------- */
/* Services Container */
.services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px;
  background: linear-gradient(-45deg, #121212, #007F52, #121212, #007F52);
  background-size: 400% 400%;
  animation: gradientBG 6s ease infinite;
  color: white;
}

/* Services Title */
.services-title {
  font-size: 3rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 40px;
  letter-spacing: 2px;
  text-align: center;
}

/* Content Layout */
.services-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 30px;
  max-width: 1200px;
  padding: 20px;
}

/* Description Text */
.services-description {
  flex: 1;
  min-width: 300px;
  max-width: 500px;
  font-size: 1.1rem;
  line-height: 1.8;
  color: #e0e0e0;
  padding: 20px;
  background: rgba(12, 12, 12, 0.8); /* Translucent box */
  border-radius: 15px;
  box-shadow: 0px 0px 20px 3px rgba(0, 127, 82, 0.7);
}

/* Images Section */
.services-images {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.services-image {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 15px;
  box-shadow: 0px 0px 20px 3px rgba(0, 127, 82, 0.7);
  transition: all 0.3s ease-in-out;
}

.services-image:hover {
  box-shadow: 0px 0px 25px 4px rgba(0, 127, 82, 1);
  transform: scale(1.05);
}

/* Return Button */
.services-btn {
  margin-top: 20px;
  padding: 12px 24px;
  border: 2px solid #007F52;
  border-radius: 15px;
  color: #007F52;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.services-btn:hover {
  background: #007F52;
  color: white;
  box-shadow: 0 8px 15px rgba(0, 127, 82, 0.8);
  transform: scale(1.05);
}

/* Responsive for Smaller Screens */
@media (max-width: 768px) {
  .services-content {
    flex-direction: column;
    text-align: center;
  }

  .services-images {
    flex-direction: column;
    align-items: center;
  }
}

