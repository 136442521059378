input:-webkit-autofill,
textarea:-webkit-autofill {
  background-color: transparent !important; /* Match the form background */
  color: #00FFA3 !important; /* Neon green text */
  box-shadow: 0 0 0 1000px rgba(12, 12, 12, 0.8) inset !important; /* Force same background */
  -webkit-text-fill-color: #00FFA3 !important; /* Force text color */
  caret-color: #00FFA3 !important; /* Cursor matches text color */
  border-radius: 8px; /* Consistent rounded corners */
  transition: background-color 5000s ease-in-out 0s; /* Prevent flash of white */
}


/* Import Roboto Font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
